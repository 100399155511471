/* src/LandingPage.css */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  font-family: Arial, Helvetica, sans-serif;
}

.landing-page {
  text-align: center;
}

.logo {
  max-width: 600px;
  height: auto; /* Keep aspect ratio */
  margin-bottom: 20px;
}

h1 {
  margin-top: -10rem;
  font-size: 2rem;
  color: #333;
}

@media (max-width: 1200px) {
  .logo {
    max-width: 500px;
  }

  h1 {
    font-size: 1.8rem;
    margin-top: -8rem;
  }
}

@media (max-width: 992px) {
  .logo {
    max-width: 400px;
  }

  h1 {
    font-size: 1.6rem;
    margin-top: -6rem;
  }
}

@media (max-width: 768px) {
  .logo {
    max-width: 300px;
  }

  h1 {
    font-size: 1.4rem;
    margin-top: -4rem;
  }
}

@media (max-width: 576px) {
  .logo {
    max-width: 200px;
  }

  h1 {
    font-size: 1.2rem;
    margin-top: -2rem;
  }
}
